import { likeRegExp } from '@/offline/database'

export default {
  _selectMaterialSistemaSubsisBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        tables.subsis.orden,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
        Vue.$offline.db.fn.sum(
          tables.material_sistema.unidades
        ).as('cant_materiales')
      )
      .from(tables.subsis)
      .innerJoin(
        tables.material_sistema,
        tables.subsis.idsubsis.eq(tables.material_sistema.idsubsis)
      )
      .groupBy(
        tables.subsis.orden,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
      )
  },
  async selectMaterialSistemaSubsis (Vue, filter, search, sorter, page, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.subsis.codigo.match(reQ),
          tables.subsis.descripcion.match(reQ),
        )
      )
    }
    if (filter.codigo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.subsis.codigo.match(likeRegExp(filter.codigo.value))
        )
      )
    }
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.subsis.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    let whereQueryFilter = []
    where.push(
      Vue.$offline.db.op.and(
        tables.subsis.idsistema.eq(idsistema),
        tables.material_sistema.estado.gt(0),
        tables.material_sistema.fdesinstalacion.isNull(),
        ...whereQueryFilter,
        ...whereSearch,
        ...whereFilter,
      )
    )
    const rows = await this._selectMaterialSistemaSubsisBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .orderBy(tables.subsis.orden)
      .orderBy(tables.subsis.descripcion)
      .orderBy(tables.subsis.idsubsis)
      .exec()
    return [rows,[]]
  },
  async selectMaterialSistemaRows (Vue, pks, idsistema) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectMaterialSistemaSubsisBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.subsis.idsistema.eq(idsistema),
          tables.subsis.idsubsis.in(pks)
        )
      )
      .exec()
    return [rows,[]]
  },
}
