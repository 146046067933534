<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      :empty-message="emptyMessage"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :page-loaded="pageLoaded"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      pk-name="subsis.idsubsis"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <material-sistema-subsis-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './MaterialSistemaSubsisListData'
import MaterialSistemaSubsisListItem from './components/MaterialSistemaSubsisListItem'
import { get } from 'vuex-pathify'
import { NextRoute } from '@/router'

const pageStoreName = 'pagesOfflineMaterialSistemaSubsisList'

export default {
  components: {
    MaterialSistemaSubsisListItem
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        addMaterialNuevo: {
          title: 'Material nuevo',
          visible: true,
          icon: 'add'
        },
        addMaterialNuevoPlantilla: {
          title: 'Material nuevo (plantillas)',
          visible: true,
          icon: 'add'
        },
      },
      emptyMessage: '',
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      const sistema = await this.$offline.sistema.row(this.routeParams.idsistema)
      this.title = `Material en ${sistema.sistema.descripcion}`
      this.emptyMessage = 'No se encontraron materiales'
      this.pageLoaded = true
      const hasInsertPermMaterialSistema = this.hasInsertPerm(this.permissions.materialSistema.id)
      this.toolbarOptions.addMaterialNuevo.visible = hasInsertPermMaterialSistema
      this.toolbarOptions.addMaterialNuevoPlantilla.visible = hasInsertPermMaterialSistema
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMaterialSistemaSubsis(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idsistema,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectMaterialSistemaRows(this, dirty.modified, this.routeParams.idsistema), dirty, 'subsis.idsubsis'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__material-sistema-list',
        params: {
          idsistema: this.routeParams.idsistema,
        },
        query: {
          idsubsis: item.data.subsis.idsubsis,
        },
      })
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.addMaterialNuevo) {
        this.$appRouter.push({
          name: 'offline__material-sistema-add',
        })
      } else if (option === this.toolbarOptions.addMaterialNuevoPlantilla) {
        this.$appRouter.push({
          name: 'offline__plantilla-articulo-list',
          params: {
            nextRoute: NextRoute(
              'offline__plantilla-articulo-aplicar-material-sistema',
              {
                idsistema: this.routeParams.idsistema,
                idplantilla_articulo: null,
              },
              {},
              true,
            ),
          }
        })
      }
    },
  }
}
</script>
